import React from 'react'

import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'

import { graphql } from 'gatsby'
import { map, path } from 'ramda'
import {
  FormattedMessage,
  FormattedHTMLMessage,
  useIntl,
  Link
} from 'gatsby-plugin-intl'
import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import routes from 'src/utils/routes'
import LogoUrl from 'src/assets/logo-bright.png'

import CertificateUrl from 'src/assets/images/tcc.png'
import PenUrl from 'src/assets/images/pen.png'

import { Button } from 'src/components/atoms/Buttons'

import { MainTitle } from 'src/components/static/common'

import {
  CertificateStatisticsSection,
  ButtonsRow,
  MobileUserTitle,
  BannerSocial,
  Logo
} from 'src/components/static/home'

import { CertificateBox } from 'src/components/static/certificate'

const Certificate = ({
  data: {
    allFile: { edges }
  }
}) => {
  const intl = useIntl()

  const backgroundImages = map(
    img => path(['node', 'childImageSharp', 'fluid'], img),
    edges
  )

  return (
    <ThemeProvider>
      <Layout>
        <MainTitle>{intl.formatMessage({ id: 'certificate.title' })}</MainTitle>
        <CertificateBox
          grayBg
          direction='row-reverse'
          title={intl.formatMessage({ id: 'certificate.text1a' })}
          // description={intl.formatMessage({ id: 'certificate.text1b' })}
          fluid={backgroundImages[0]}
        />
        <CertificateBox
          title={intl.formatMessage({ id: 'certificate.text2title' })}
          listItems={[
            intl.formatMessage({ id: 'certificate.text2a' }),
            intl.formatMessage({ id: 'certificate.text2b' }),
            intl.formatMessage({ id: 'certificate.text2c' }),
            intl.formatMessage({ id: 'certificate.text2d' }),
            intl.formatMessage({ id: 'certificate.text2e' })
          ]}
          fluid={backgroundImages[1]}
        />
        <CertificateBox
          direction='row-reverse'
          title={intl.formatMessage({ id: 'certificate.text3title' })}
          listItems={[
            intl.formatMessage({ id: 'certificate.text3a' }),
            // intl.formatMessage({ id: 'certificate.text3b' }),
            intl.formatMessage({ id: 'certificate.text3c' })
            // intl.formatMessage({ id: 'certificate.text3d' })
          ]}
          fluid={backgroundImages[2]}
        />
        <CertificateStatisticsSection>
          <CertificateWrapper>
            <PenWrapper>
              <img src={PenUrl} alt='certificate' />
            </PenWrapper>
            <ImageCertificateWrapper>
              <img src={CertificateUrl} alt='certificate' />
            </ImageCertificateWrapper>
          </CertificateWrapper>
          <TextWrapper>
            <BoldTextWrapper>
              {intl.formatMessage({ id: 'certificate.text4title' })}
            </BoldTextWrapper>
            <FormattedHTMLMessage id='certificate.text4subTitle' />
          </TextWrapper>
        </CertificateStatisticsSection>
        <BannerSocial>
          <Logo src={LogoUrl} alt='logo' />
          <MobileUserTitle>
            <FormattedMessage id='homePage.joinSectionTitle' />
          </MobileUserTitle>
          <ButtonsRow>
            <Link to={routes.SIGN_UP}>
              <Button active>
                <FormattedMessage id='common.register' />
              </Button>
            </Link>
          </ButtonsRow>
        </BannerSocial>
      </Layout>
    </ThemeProvider>
  )
}

export const query = graphql`
  {
    allFile(
      sort: { fields: name }
      filter: { name: { regex: "/certificate/" } }
    ) {
      edges {
        node {
          publicURL
          name
          childImageSharp {
            fluid(quality: 100, maxHeight: 300) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default Certificate

const CertificateWrapper = styled.div`
  display: none;
  width: 50%;
  ${breakpoint.sm`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  `}
`
const TextWrapper = styled.div`
  color: ${({ grayBg }) => (grayBg ? 'white' : 'rgb(87, 92, 88)')};
  box-sizing: border-box;
  padding: 2.4rem 5%;
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase ${breakpoint.sm`
    padding: 2.2rem;
    width: 50%;
  `};
`
const BoldTextWrapper = styled.div`
  font-weight: 700;
`
const ImageCertificateWrapper = styled.div`
  display: flex;
  height: 600px;
  width: auto;
  margin-left: 0;
  margin-bottom: 1.6rem;
  ${breakpoint.sm`
    margin-bottom: 0;
  `}
`
const PenWrapper = styled.div`
  padding-top: 274px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 326px;
  width: auto;
  margin-bottom: 1.8rem;
  ${breakpoint.m`
    margin-bottom: 0;
  `}
`
