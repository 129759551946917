import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { breakpoint } from 'src/theme/grid'
import GreenBox from 'src/assets/images/list-style-green.png'

const ImageBox = styled.div`
  display: none;
  width: 50%;
  ${breakpoint.sm`
    display: grid;
  `}
`

const CertificateBoxComponent = ({
  className,
  title,
  description,
  listItems = [],
  fluid
}) => (
  <div className={className}>
    <div>
      <div>
        <TitleWrapper>{title}</TitleWrapper>
        <div>{description}</div>
      </div>
      {listItems.length > 0 && (
        <ListWrapper>
          <ul>
            {listItems.map(listItem => (
              <li>{listItem}</li>
            ))}
          </ul>
        </ListWrapper>
      )}
    </div>
    <ImageBox>
      <Img fluid={fluid} />
    </ImageBox>
  </div>
)

export const CertificateBox = styled(CertificateBoxComponent)`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: center;
  & > div:first-of-type {
    background-color: ${({ grayBg }) => (grayBg ? '#646466' : 'white')};
    color: ${({ grayBg }) => (grayBg ? 'white' : 'rgb(87, 92, 88)')};
    box-sizing: border-box;
    padding: 2.4rem 10%;
    margin: 0;
    font-weight: 400;
    font-size: 1.2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${breakpoint.sm`
      width: 50%;
    `}
  }
`
const ListWrapper = styled.div`
  display: block;
  ul {
    padding-left: 2.8rem;
    list-style: none;
    li {
      margin-bottom: 0.6rem;
      position: relative;
      line-height: 1.6;
      font-size: 1.1rem;
      &:before {
        content: '';
        background-image: url(${GreenBox});
        background-color: white;
        background-size: contain;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 6px;
        left: -43px;
      }
    }
  }
`
const TitleWrapper = styled.div`
  // text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 10px;
`
